// Body

// Typography
$roboto: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
$arial: "Arial", Sans-serif;
$fontawesome: 'Font Awesome 5 Pro';

// Colors
$color-main: #f3aa2c;
$color-hover: #FFBB46;
$color-secondary: #680102;
$color-secondary-hover: #900001;
$color-black: #000000;
$color-white: #ffffff;
$color-medBlack: #333333;
$color-border: #cccccc;
$color-darkBorder: #666666;
$color-gray: #55595c;
$color-ash: #F8F8F8;
$color-formColor: #F1F2F1;
$color-formDarkColor: #E5E5E5;
$color-tableColor: #e6e6e6;
$color-icon: #818a91;